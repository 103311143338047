* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 87.5%;
  line-height: 1.4;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
}

b,
strong {
  font-weight: bolder;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  overflow: visible;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

details {
  display: block;
}

summary {
  display: list-item;
}

/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */
.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */
.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

a {
  color: hsl(240, 60%, 50%);
  text-decoration: none;
}

a,
button:enabled {
  cursor: pointer;
}

input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  border: 1px solid hsl(220, 33%, 92%);
  background-color: transparent;
  padding: 0.3em 0.6em;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: hsl(0, 0%, 75%);
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: transparent;
  background-color: rgba(243, 245, 249, 0.4);
}

.input--white {
  color: white;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
}
.input--white:focus {
  background-color: transparent;
  border-color: black;
}
.input--disabled {
  opacity: 0.6;
}

input.amount-input {
  width: 5em;
  text-align: right;
}

textarea {
  display: block;
}
textarea:disabled {
  resize: none;
}
textarea.auto-resize {
  resize: none;
  overflow: hidden;
}

select {
  padding: 0.3em;
}

.checkbox--aligned {
  vertical-align: middle;
}

.clickable {
  cursor: pointer;
}

.nowrap, .numeric {
  white-space: nowrap;
}

.numeric {
  font-family: "Roboto", sans-serif;
}

.flex {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-inline {
  display: inline-flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.inline {
  display: inline-block;
}

.inline-top {
  display: inline-block;
  vertical-align: top;
}

.text-center, table.main-table th.text-center {
  text-align: center;
}

.text-right, table.main-table th.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

.text-multiline, .warning-panel .warning, table#ui-error-log td.details .original-stack, .raw-json, .invoice__comment-area, .expense__comment-area {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.top-margin, .top-bottom-margin {
  margin-top: 0.5em;
}
.top-margin.medium, .medium.top-bottom-margin {
  margin-top: 1.2em;
}
.top-margin.extra, .extra.top-bottom-margin {
  margin-top: 2em;
}

.bottom-margin, .top-bottom-margin {
  margin-bottom: 0.5em;
}
.bottom-margin.extra, .extra.top-bottom-margin {
  margin-bottom: 2em;
}

.left-margin {
  margin-left: 0.5em;
}

.right-margin {
  margin-right: 0.5em;
}

.align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.full-width {
  width: 100%;
}

.relative {
  position: relative;
}

.delete-icon {
  width: 10px;
  height: 16px;
}

.view-icon {
  width: 19px;
  height: 12px;
  vertical-align: middle;
}

table.main-table th {
  text-align: left;
  font-weight: 600;
}
.content-area {
  position: relative;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.05em;
  min-height: 55em;
  margin: 4em;
}
.content-area .main-table.unwrapped, .content-area div.table-wrapper-outer.main-table-wrapper {
  margin-top: 2em;
}
.content-area div.table-wrapper-outer.main-table-wrapper .table-wrapper-inner {
  width: 100%;
}
.content-area table.main-table {
  width: 100%;
}
.content-area table.main-table td.actions {
  text-transform: uppercase;
  padding-left: 2em;
  white-space: nowrap;
}
.content-area table.main-table td.actions span > :not(:first-child) {
  margin-left: 2.5em;
}
.content-area table.main-table td.actions a {
  font-weight: bold;
}

.content {
  position: relative;
  flex-grow: 1;
  padding: 3em;
  background-color: white;
}

.title {
  margin: 0;
  padding-top: 2em;
  padding-bottom: 1.7em;
  font-size: 150%;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.title__sub-title {
  font-weight: 300;
  font-size: 80%;
  margin-left: 0.6em;
  letter-spacing: 0;
  text-transform: none;
}

.section-title {
  font-size: 100%;
  margin-top: 3em;
  letter-spacing: 0.15em;
}

table.align-top td,
table.align-top th {
  vertical-align: top;
}

.amount {
  text-align: right;
  white-space: nowrap;
}

input.amount {
  width: 7em;
}

.text-small {
  font-size: 10px;
}

.text-warning, .clear-button {
  color: #ff4350;
}

.sidebar-warning {
  color: black;
}

.clear-button {
  font-weight: bold;
  font-size: 120%;
  margin-left: 0.3em;
}

.back-link {
  position: absolute;
  left: 1em;
  top: 1em;
}
.back-link img {
  width: 1.65rem;
}

.sort-options .button-group {
  margin: 0 0.5em;
}

.loading-page {
  font-size: 12px;
  padding: 4em;
}

.button {
  font-family: "Raleway", sans-serif;
  display: inline-block;
  font-size: 0.85714rem;
  border-radius: 0.2em;
  border: none;
  padding: 0.3em 1em 0.2em;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.6;
}
.button:disabled {
  opacity: 0.5;
}
.button--wide {
  width: 18em;
}
.button--min-width {
  min-width: 7em;
}
.button--primary, .button--primary-selected, .button--primary-light {
  border: 1px solid transparent;
}
.button--primary:focus, .button--primary-selected:focus, .button--primary-light:focus {
  outline: none;
  border-color: black;
}
.button--primary {
  background-color: hsl(208, 40%, 67%);
  color: white;
}
.button--primary:hover:enabled {
  background-color: hsl(208, 40%, 60%);
}
.button--primary-selected {
  background-color: hsl(208, 40%, 55%);
  color: white;
}
.button--primary-light {
  background-color: hsl(210, 43%, 92%);
  color: hsl(0, 0%, 25%);
  font-weight: 600;
}
.button--primary-light:hover:enabled {
  background-color: hsl(210, 43%, 85%);
}
.button--secondary, .button--secondary-selected {
  color: hsl(0, 0%, 25%);
  font-weight: 600;
}
.button--secondary:focus, .button--secondary-selected:focus {
  outline: none;
  border-color: hsl(0, 0%, 50%);
}
.button--secondary {
  background-color: white;
  border: 1px solid hsl(0, 0%, 75%);
}
.button--secondary:hover:enabled {
  background-color: hsla(0, 0%, 0%, 0.06);
}
.button--secondary-selected {
  background-color: hsl(0, 0%, 90%);
  border: 1px solid hsl(0, 0%, 70%);
}
.button--white {
  background-color: white;
  border: 1px solid transparent;
  color: black;
}
.button--white:hover:not(:disabled) {
  opacity: 0.8;
}
.button--white:focus {
  outline: none;
  border-color: black;
}
.button--transparent {
  background: transparent;
  color: white;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
}
.button--transparent:hover:not(:disabled) {
  background-color: hsla(0, 0%, 0%, 0.2);
}
.button--transparent:focus {
  outline: none;
  border-color: black;
}
.button--transparent-selected {
  background-color: hsla(0, 0%, 0%, 0.2);
  color: white;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
}
.button--transparent-selected:focus {
  outline: none;
  border-color: black;
}
.button--numeric {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.date-button {
  font-family: "Roboto", sans-serif;
  border: 1px solid hsl(220, 33%, 92%);
  background-color: transparent;
  padding: 0.3em 0.6em;
  padding-bottom: 0.2em;
  letter-spacing: 0.05em;
  border-radius: 0;
  text-transform: none;
  min-width: 7em;
  white-space: nowrap;
}
.date-button::placeholder {
  color: hsl(0, 0%, 75%);
}
.date-button:hover:enabled {
  background-color: rgba(243, 245, 249, 0.4);
}
.date-button:focus {
  outline: none;
  border-color: black;
}
.date-button--white {
  color: white;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
}
.date-button--white:hover:enabled {
  background-color: hsla(0, 0%, 0%, 0.2);
}
.date-button--white-bg {
  background-color: white;
  border: 1px solid transparent;
  color: black;
  border-radius: 0.2em;
  text-transform: uppercase;
}
.date-button--white-bg:hover:not(:disabled) {
  opacity: 0.8;
}
.date-button--white-bg:focus {
  outline: none;
  border-color: black;
}

.table-wrapper-outer {
  position: relative;
}

.table-wrapper-inner {
  max-height: 30em;
  overflow-y: auto;
  display: inline-block;
}

table {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  border-collapse: collapse;
  border-spacing: 0;
}
table thead {
  font-size: 110%;
  background-color: hsl(210, 60%, 93%);
}
table thead.floating {
  position: absolute;
  top: 0;
  z-index: 1;
}
table thead.floating.fixed {
  position: fixed;
  transform: scaleX(0);
}
table td,
table th {
  border: none;
  padding: 0.8em 1em;
}
table th,
table tr.totals {
  text-transform: uppercase;
}
table tr.bottom td {
  background-color: hsl(210, 60%, 93%);
}
table tr.bottom input.amount {
  border-color: hsl(0, 0%, 80%);
}
table tr.totals {
  font-size: 110%;
  font-weight: 600;
  border-top: 1px solid hsl(210, 60%, 93%);
  border-bottom: none;
}
table tr.shaded td,
table td.shaded {
  background-color: hsl(198, 51%, 97%);
}

a.payment-link,
a.confirm-link,
a.schedule-link {
  color: black;
}

.table--bottom-border {
  border-bottom: 1px solid hsl(210, 60%, 93%);
}
.table__row--bordered {
  border-bottom: 1px solid hsl(210, 60%, 93%);
}
.table__body-cell--bordered {
  border-right: 1px solid hsl(210, 60%, 93%);
}
.table__body-cell--no-left-pad {
  padding-left: 0;
}
.table__body-cell--no-top-pad {
  padding-top: 0;
}

input.account-input {
  width: 9em;
}

svg.arc-graph circle {
  fill: none;
  transform-origin: center;
  transform: rotate(-90deg);
}
svg.arc-graph circle.bg {
  stroke: black;
  stroke-width: 1;
}
svg.arc-graph circle.fg {
  stroke: #60599d;
}
svg.arc-graph text {
  font-family: Roboto, sans-serif;
}
svg.arc-graph text.percentage {
  font-size: 22px;
  font-weight: 900;
  fill: #60599d;
}
svg.arc-graph text.amount {
  font-size: 9px;
}

.archive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.archive__section-button {
  font-size: 125%;
  font-weight: 500;
  display: block;
  width: 100%;
  margin-top: 1.5em;
  padding: 0.9em 0;
}
.archive__title {
  font-size: 130%;
  padding: 1em 0;
}
.archive__date {
  padding-bottom: 0.5em;
}
.archive__date-label {
  display: inline-block;
  width: 4em;
}
.archive__date-input {
  width: 12em;
}
.archive__date-shortcut {
  width: 12em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  margin-left: 0;
}
.archive__filter-summary-title {
  font-weight: bold;
  font-size: 90%;
  margin-bottom: 0.2em;
}
.archive__filter-summary {
  border: 1px solid #ccc;
  display: inline-block;
  font-size: 85%;
  padding: 0.15em 0.3em;
}
.archive__result-table {
  min-width: 70em;
}

.arrow {
  cursor: pointer;
  width: 10px;
  height: 10px;
  vertical-align: middle;
}
.arrow--left {
  transform: rotate(90deg);
}
.arrow--right {
  transform: rotate(-90deg);
}

.auto-complete {
  position: absolute;
  background-color: white;
  border: 1px solid hsl(220, 33%, 92%);
  min-width: 12em;
  white-space: normal;
  max-height: 25em;
  overflow-y: auto;
}
.auto-complete .match,
.auto-complete .add-new {
  cursor: pointer;
}
.auto-complete .match,
.auto-complete .add-new,
.auto-complete .add-info {
  padding: 0.2em 0.5em;
}
.auto-complete .match.highlighted,
.auto-complete .add-new.highlighted {
  background-color: hsl(200, 50%, 90%);
}
.auto-complete .match.default {
  font-style: italic;
}
.auto-complete .add-new {
  font-style: italic;
}
.auto-complete .add-new::before {
  content: "+";
  color: hsl(100, 50%, 40%);
  font-weight: bold;
  margin-right: 0.2em;
  font-size: 160%;
  line-height: 0.6;
  vertical-align: text-top;
}
.auto-complete .add-info {
  color: hsl(0, 0%, 60%);
  max-width: 15em;
}

.open-auto-complete {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 0.5em;
}

.balance__side-title {
  font-size: 1rem;
  width: 8rem;
  text-transform: uppercase;
  border-bottom: 1px solid hsl(210, 60%, 93%);
  padding-bottom: 1em;
}
.balance__date-row-cell {
  font-size: 110%;
  background-color: hsl(210, 60%, 93%);
  text-align: right;
  font-weight: bold;
}
.balance__cell--invalid {
  color: #ff4350;
}
.balance__account-name {
  min-width: 30em;
}
.balance__account-name--total {
  font-weight: bold;
}
.balance__account-name--level2 {
  padding-left: 1.5em;
}
.balance__account-name--level3 {
  padding-left: 3em;
}
.balance__account-name--level4 {
  padding-left: 4.5em;
}
.balance__padding {
  min-width: 6em;
}
.balance__total-row-cell {
  border-top: 1px solid hsl(210, 60%, 93%);
}

div#banners {
  position: sticky;
  top: 0;
  z-index: 3;
}
div#banners .banner {
  background: linear-gradient(90deg, #4e328c 0%, hsl(203, 46%, 70%) 100%);
  padding: 0.3em;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
div#banners .banner__session-expires {
  font-size: 120%;
  font-weight: 400;
}
div#banners .banner__button-section {
  margin: 0.7em 0;
}

.business-lookup {
  display: flex;
}
.business-lookup__input {
  width: 18em;
}
.business-lookup__input--search:not(:focus) {
  background-image: url(./icons/search.svg);
  background-repeat: no-repeat;
  background-position: 100%;
  background-origin: content-box;
}
.business-lookup__matches-title {
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 0.4em;
  padding-left: 0.5em;
  padding-bottom: 0.7em;
}
.business-lookup__matches {
  width: 25em;
  background-color: white;
}
.business-lookup__matches--popup {
  position: absolute;
  z-index: 2;
  border: 1px solid hsl(220, 33%, 92%);
}
.business-lookup__match-section:not(:first-child) {
  border-top: 1px solid hsl(210, 60%, 93%);
}
.business-lookup__match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.3em 0.5em;
}
.business-lookup__match:hover {
  background-color: hsl(210, 43%, 97%);
}
.business-lookup__match-note {
  font-size: 70%;
  line-height: 100%;
  color: #999;
}

.change-company {
  font-family: "Raleway", sans-serif;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}
.change-company__password {
  font-family: "Raleway", sans-serif;
  color: white;
  padding: 0.7em 1.6em;
  margin-bottom: 0.5em;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  text-align: center;
  width: 90%;
  max-width: 22em;
}
.change-company__password::placeholder {
  color: white;
}
.change-company__password:focus {
  outline: none;
  background-color: hsla(0, 0%, 0%, 0.03);
}
.change-company__password--invalid {
  color: hsl(0, 80%, 65%);
  border-bottom-color: hsl(0, 80%, 65%);
}
.change-company__button {
  color: #83929e;
  font-size: 14px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.2em;
  padding: 0.7em 0 0.5em;
  width: 90%;
  max-width: 22em;
  letter-spacing: 0.5px;
}
.change-company__button:hover {
  background-color: hsl(0, 0%, 95%);
}
.change-company__button:focus {
  outline: none;
  border-color: hsla(0, 0%, 0%, 0.5);
}

.button-group {
  display: inline-block;
}
.button-group button {
  margin-bottom: 0.4em;
}
.button-group button:not(:last-child) {
  margin-right: 0.4em;
}
.button-group.vertical button {
  display: block;
}

div#unprocessed-info {
  color: hsl(0, 100%, 50%);
}

table#command-log tr.success {
  background-color: hsl(103, 44%, 89%);
}
table#command-log tr.warning {
  background-color: hsl(50, 81%, 94%);
}
table#command-log tr.failure {
  background-color: hsl(0, 43%, 91%);
}
table#command-log tr.unprocessed {
  background-color: hsl(0, 100%, 80%);
}
table#command-log td.toggle {
  white-space: nowrap;
  padding: 0 0.5em;
}
table#command-log td.toggle button {
  min-width: auto;
  color: black;
  font-size: 30px;
  padding: 0 0.1em 0 0.2em;
  line-height: 0.7;
  background: hsla(0, 0%, 0%, 0.12);
  margin-top: 0.2em;
  outline: none;
  border-radius: 0.15em;
}
table#command-log td.details {
  max-width: 60em;
}
table#command-log td.params,
table#command-log td.response {
  max-width: 25em;
}
table#command-log span.alert {
  font-weight: bold;
  color: red;
  margin-left: 0.3em;
  font-size: 125%;
  vertical-align: super;
}

.dashboard-panel {
  background-color: hsla(0, 0%, 0%, 0.1);
  padding: 2em;
  min-width: 18em;
  color: white;
}
.dashboard-panel--titled {
  display: flex;
  flex-direction: column;
}
.dashboard-panel__title {
  flex-grow: 0;
  align-self: center;
  text-transform: uppercase;
  font-size: 120%;
  letter-spacing: 0.05em;
  text-align: center;
}
.dashboard-panel__content {
  flex-grow: 1;
}
.dashboard-panel__content--centered, .dashboard-panel__content--numeric {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboard-panel__content--numeric {
  font-size: 500%;
  font-weight: 200;
  white-space: nowrap;
}
.dashboard-panel__content--text {
  font-size: 140%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.dashboard-panel__notes-content {
  display: flex;
  flex-direction: column;
}
.dashboard-panel__notes-input {
  flex-grow: 1;
  margin: 1em 0;
  color: white;
}
.dashboard-panel__notes-input:not(:focus) {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.dashboard-panel__notes-input:focus {
  background-color: transparent;
  border-color: hsla(0, 0%, 100%, 0.4);
}

.dashboard {
  font-size: 85.714%;
  display: grid;
  grid: 1fr 1fr 1fr/1fr 1fr 1fr;
  grid-gap: 4em;
  gap: 4em;
  padding: 4em;
  min-height: 70em;
  grid-template-areas: "date-or-warning revenue         notes   " "add-new         add-new         notes   " "unpaid-revenues unpaid-expenses calendar";
}
.dashboard__link {
  color: white;
}
.dashboard__link--disabled {
  opacity: 0.5;
}
.dashboard__link--nav {
  user-select: none;
  font-size: 150%;
  line-height: 1;
}
.dashboard__date {
  grid-area: date-or-warning;
}
.dashboard__vat-warning {
  grid-area: date-or-warning;
  align-items: center;
  text-align: center;
}
.dashboard__revenue {
  grid-area: revenue;
  align-items: center;
  text-align: center;
}
.dashboard__revenue-no-data {
  font-size: 250%;
  max-width: 12em;
  font-weight: 300;
  opacity: 0.7;
  letter-spacing: 0.1em;
}
@media (max-width: 1550px) {
  .dashboard__revenue-no-data {
    font-size: 200%;
  }
}
@media (max-width: 1350px) {
  .dashboard__revenue-no-data {
    font-size: 150%;
  }
}
.dashboard__notes {
  grid-area: notes;
}
.dashboard__add-new {
  grid-area: add-new;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.dashboard__add-new-link {
  color: white;
}
.dashboard__add-new-link:hover {
  opacity: 0.6;
}
.dashboard__add-new-text {
  text-align: center;
  text-transform: uppercase;
  margin: 0.7em 0;
  font-size: 120%;
  letter-spacing: 0.05em;
}
.dashboard__add-new-icon {
  width: 8em;
  height: 8em;
}
.dashboard__unpaid-revenues {
  grid-area: unpaid-revenues;
}
.dashboard__unpaid-expenses {
  grid-area: unpaid-expenses;
}
.dashboard__calendar {
  grid-area: calendar;
  font-size: 160%;
  padding: 1em;
  min-height: 13.5em;
}
.dashboard__calendar--loading {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1550px) {
  .dashboard__calendar {
    font-size: 140%;
  }
}
.dashboard__calendar-header {
  font-size: 70%;
  padding: 0 1.2em 0.3em;
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
  text-transform: capitalize;
}
.dashboard__day-picker-wrapper:focus {
  outline: none;
}
.dashboard__weekday {
  color: white;
  font-size: 100%;
  padding: 0.1em 0.5em;
}
.dashboard__day {
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 200;
  border-radius: 0;
  padding: 0.1em 0.5em;
}
.dashboard__day--today {
  color: white;
}
.dashboard__day--selected {
  background-color: white;
  color: black;
}
.dashboard__day--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.dashboard__day--outside {
  pointer-events: none;
  background-color: transparent;
}
.dashboard__wide-button {
  width: 100%;
}

.email-confirmation {
  margin-top: 4em;
  text-align: center;
  color: white;
}
.email-confirmation__message {
  font-size: 150%;
  font-weight: 200;
  line-height: 1.9;
  white-space: pre-wrap;
}
.email-confirmation__login-button {
  color: #83929e;
  font-size: 14px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.2em;
  padding: 0.7em 0 0.5em;
  width: 90%;
  max-width: 22em;
  letter-spacing: 0.5px;
  margin-top: 2em;
}
.email-confirmation__login-button:hover {
  background-color: hsl(0, 0%, 95%);
}
.email-confirmation__login-button:focus {
  outline: none;
  border-color: hsla(0, 0%, 0%, 0.5);
}

.entries--edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.entries__table {
  min-width: 60em;
}
.entries__label {
  display: inline-block;
  width: 10em;
}
.entries__copy-link {
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}
.entries__description-input {
  width: 25em;
}
.entries__item-table {
  margin-top: 0.5em;
  min-width: 60em;
}

.entry-presets {
  padding-left: 1em;
}
.entry-presets__title {
  font-weight: bold;
  padding: 0.75em 1em;
  text-transform: uppercase;
  background-color: hsl(210, 60%, 93%);
}
.entry-presets__button {
  width: 100%;
  text-align: left;
  margin-top: 0.5em;
}

.error-panel {
  margin: auto;
  max-width: 50em;
  padding: 1.4em 1.6em;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
.error-panel .close-button {
  cursor: pointer;
  float: right;
  font-size: 200%;
  line-height: 0.5;
}
.error-panel .highlighted {
  font-size: 120%;
  font-weight: bold;
  margin-bottom: 0.3em;
}
.error-panel .highlighted:not(.first) {
  margin-top: 1em;
}
.error-panel a.details-link {
  font-size: 90%;
  color: inherit;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0.3em 1.2em;
}
.error-panel table {
  width: 100%;
  font-size: 90%;
  margin-top: 2em;
}
.error-panel table th {
  text-align: left;
}
.error-panel table td.time,
.error-panel table th.time {
  width: 1px;
  white-space: nowrap;
}
.error-panel table td.message,
.error-panel table th.message {
  white-space: pre-wrap;
}
.error-panel table tr:last-child td {
  border-bottom: none;
}

img.expand-collapse {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 0.5em;
}
img.expand-collapse--expand {
  transform: rotate(-90deg);
}

.expense__change-note {
  text-align: right;
  color: hsl(0, 100%, 40%);
}
.expense__top {
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
}
.expense__general-input {
  font-size: 12px;
  width: 12em;
}
.expense__bottom {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}
.expense__add-buttons {
  margin: 1em 0;
}
.expense__add-button-line {
  margin-top: 0.25em;
}
.expense__comment-area {
  margin-top: 4em;
  min-height: 12em;
  display: inline-block;
}
.expense__comment {
  width: 40em;
  height: 12em;
  min-width: 25em;
  max-width: 50em;
}
.expense__bottom-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

div.table-wrapper-outer.asset-table-wrapper .table-wrapper-inner {
  width: 100%;
}

table#assets {
  width: 100%;
}
table#assets td.center {
  text-align: center;
}

table.unpaid-expenses th.pad-before {
  width: 2.5em;
}
table.unpaid-expenses th.due-first {
  text-align: center;
  padding-bottom: 0;
}
table.unpaid-expenses th.due-second {
  text-align: right;
  padding-top: 0;
  white-space: nowrap;
}

.stock-list__unconfirmed {
  min-width: 50em;
}
.stock-list__calc-button {
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  padding: 0.1em 0.3em;
  border-radius: 0;
}
.stock-list__input-cell {
  text-align: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  white-space: nowrap;
}
.stock-list__disabled-row {
  color: hsl(0, 0%, 80%);
}

.asset-change {
  line-height: 1.6;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  transition: background-color 1s;
}
.header div#user-info {
  text-align: right;
  display: flex;
  align-items: center;
  margin-right: 2em;
}
.header div#user-info select {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.03em;
}
.header div#user-info select {
  border: none;
  padding-top: 4px;
  background: transparent;
}
.header div#user-info img#logout {
  cursor: pointer;
}
.header div#user-info img#logout,
.header div#user-info svg {
  height: 1.2em;
}
.header div#titles {
  flex-grow: 1;
  padding: 0.5em 0 0.25em 2em;
  transition: all 1s;
}
.header div#titles div#main-title {
  text-align: center;
  width: 136px;
  transition: all 1s;
}
.header div#titles div#main-title svg {
  vertical-align: -2px;
  width: 136px;
  max-width: 136px;
  transition: all 1s;
  fill: url(#logo-gradient);
}
.header--login {
  background-color: transparent;
}
.header--login div#titles {
  padding-top: 8em;
  padding-left: 0;
}
.header--login div#titles div#main-title {
  width: 100%;
  margin-top: 5em;
  margin-bottom: 2em;
}
.header--login div#titles div#main-title svg {
  width: 90%;
  max-width: 33em;
  fill: white;
}

.profile-link {
  line-height: 0;
}

.profile-icon {
  cursor: pointer;
  height: 1.2em;
  margin-left: 0.9em;
  margin-right: 1.2em;
}

.init-company {
  display: flex;
  padding: 5em 0;
  color: white;
}
.init-company .validation-error {
  color: black;
}
.init-company__nav {
  font-family: "Raleway", sans-serif;
  flex-basis: 20%;
  padding: 1em 2em;
  font-size: 110%;
  letter-spacing: 0.15em;
}
.init-company__nav-item {
  text-transform: uppercase;
  font-weight: 200;
}
.init-company__nav-item--active {
  font-weight: 500;
}
.init-company__nav-item--disabled {
  color: hsla(0, 0%, 100%, 0.5);
}
.init-company__nav-item:not(:first-child) {
  margin-top: 4em;
}
.init-company__link, .init-company__nav-link {
  color: white;
}
.init-company__link:hover, .init-company__nav-link:hover {
  color: hsla(0, 0%, 100%, 0.7);
}
.init-company__link {
  font-weight: bold;
}
.init-company__main {
  flex-basis: 60%;
  background-color: hsla(0, 0%, 0%, 0.1);
  padding: 3em;
  letter-spacing: 0.05em;
}
.init-company__main--center {
  display: flex;
  justify-content: center;
}
.init-company__main-inner {
  width: 20em;
  margin: auto;
  text-align: center;
}
.init-company__main-inner--bottom {
  margin-top: 3em;
}
.init-company__title {
  font-size: 140%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 2em;
}
.init-company__input {
  color: white;
  padding: 0.5em;
  padding-left: 1em;
  background-color: hsla(0, 0%, 0%, 0.1);
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  letter-spacing: 0.05em;
}
.init-company__input::placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}
.init-company__input:focus {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-color: hsla(0, 0%, 100%, 0.5);
}
.init-company__input--wide {
  width: 100%;
}
.init-company__input--left {
  width: 1em;
  flex-grow: 1;
  margin-right: 0.5em;
}
.init-company__input--right {
  width: 1em;
  flex-grow: 2;
}
.init-company__input--small {
  font-size: 90%;
}
.init-company__input-row {
  margin-bottom: 1em;
}
.init-company__flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.init-company__input-label {
  text-align: left;
  margin-bottom: 0.2em;
}
.init-company__text-row {
  padding: 0.5em;
}
.init-company__text--bold {
  font-weight: 500;
}
.init-company__text--thin {
  font-weight: 200;
}
.init-company__dropdown {
  background-color: hsla(0, 0%, 0%, 0.1);
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
  color: white;
}
.init-company__dropdown option {
  color: black;
}
.init-company__dropdown:focus {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-color: hsla(0, 0%, 100%, 0.5);
}
.init-company__dropdown--left {
  flex-grow: 1;
  margin-right: 0.5em;
}
.init-company__dropdown--right {
  flex-grow: 2;
}
.init-company__button--wide {
  width: 100%;
}
.init-company__button-row {
  margin-top: 2em;
}
.init-company__general-hint {
  font-size: 80%;
  text-align: center;
  padding-bottom: 1em;
  font-weight: 200;
}
.init-company__vat-info {
  padding-bottom: 1em;
}
.init-company__date-page {
  font-size: 90%;
  max-width: 50em;
  margin: auto;
  font-weight: 300;
}
.init-company__date-type-buttons {
  padding: 1em 0;
}
.init-company__date-type-button {
  min-width: 30em;
  text-align: left;
}
.init-company__billing-fields, .init-company__payment-info {
  flex-basis: 25em;
  padding: 0 2em;
}
.init-company__payment-info {
  font-weight: 300;
  font-size: 90%;
  padding-top: 2.6em;
}
.init-company__billing-company-title {
  padding-bottom: 1em;
}
.init-company__billing-company-field {
  display: flex;
  padding: 0.4em 0.5em;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
  margin-bottom: 1em;
}
.init-company__billing-company-label {
  font-size: 80%;
  flex-basis: 9em;
  flex-shrink: 0;
  line-height: 1.4rem;
  font-weight: 300;
  opacity: 0.6;
}
.init-company__billing-company-value {
  white-space: pre-wrap;
}
.init-company__billing-contact-title {
  padding-top: 1.5em;
  padding-bottom: 1em;
}
.init-company__billing-bottom {
  padding-top: 1em;
  text-align: center;
}
.init-company__credit-card-icons {
  padding: 0.5em;
}
.init-company__credit-card-icon {
  margin: 0 0.5em;
}
.init-company__payment-button-row {
  padding-top: 1em;
}
.init-company__payment-button {
  width: 100%;
  max-width: 20rem;
}
.init-company__billing-footer {
  font-size: 80%;
  opacity: 0.6;
  text-align: center;
  margin-top: 2em;
  letter-spacing: 0.15em;
}
.init-company__already-paid {
  text-align: center;
}
.init-company__payment-status {
  font-weight: 300;
}
.init-company__payment-title {
  font-size: 120%;
  font-weight: 400;
  padding-bottom: 1em;
}
.init-company__payment-sub-title {
  font-size: 120%;
  font-weight: 400;
  padding-top: 3em;
  padding-bottom: 1em;
}
.init-company__start-using {
  margin-top: 3em;
}

.invoice__top {
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
}
.invoice__vendor {
  margin-top: 4em;
  height: 7em;
}
.invoice__vendor-name {
  margin: 0;
  font-size: 150%;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.invoice__customer-details {
  margin-top: 1em;
}
.invoice__citizen-details {
  width: 16em;
  height: 6em;
  min-width: 16em;
  max-width: 50em;
}
.invoice__date-button {
  width: 12em;
}
.invoice__term-input {
  width: 5em;
  text-align: right;
  width: 3em;
}
.invoice__bottom {
  padding-top: 2em;
  display: flex;
  justify-content: space-between;
}
.invoice__comment-area {
  margin-top: 11em;
  min-height: 12em;
}
.invoice__comment {
  width: 40em;
  height: 12em;
  min-width: 25em;
  max-width: 50em;
}
.invoice__bottom-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

table.unpaid-invoices th.pad-before {
  width: 2.5em;
}
table.unpaid-invoices th.overdue-first {
  text-align: center;
  padding-bottom: 0;
}
table.unpaid-invoices th.overdue-second {
  text-align: right;
  padding-top: 0;
  white-space: nowrap;
}

.invoice-search__input, .invoice-search__save-button {
  width: 100%;
}

div.table-wrapper-outer.item-table-wrapper .table-wrapper-inner {
  width: 100%;
}

table#items {
  width: 100%;
}
table#items td span.read-only {
  line-height: 1.8;
}
table#items td div.account-cell {
  display: flex;
  justify-content: space-between;
}
table#items td.center {
  text-align: center;
}
table#items input.quantity,
table#items input.unit-price,
table#items input.discount,
table#items input.payable-without-vat {
  width: 5em;
  text-align: right;
}

.raw-json .indented {
  margin-left: 1em;
}
.raw-json .json-attr {
  color: hsl(80, 100%, 30%);
}
.raw-json .json-string {
  color: hsl(20, 100%, 40%);
}
.raw-json .json-literal {
  color: hsl(200, 100%, 40%);
}

.labour-cost {
  line-height: 1.6;
}
.labour-cost__main {
  width: 100%;
  padding-bottom: 1em;
}
.labour-cost__month {
  font-size: 130%;
}
.labour-cost__separator {
  border: 0;
  border-bottom: 1px solid white;
  width: 12em;
}
.labour-cost__links {
  padding-left: 1em;
}
.labour-cost__buttons {
  align-self: stretch;
}

.labour-calc-table {
  font-size: 90%;
  margin: 1em 0;
  width: 100%;
}
.labour-calc-table__cell {
  padding: 0.2em 0;
}
.labour-calc-table__cell--text {
  padding-right: 0.5em;
  word-break: break-word;
}
.labour-calc-table__cell--amount {
  text-align: right;
  white-space: nowrap;
  min-width: 6em;
}

table#log {
  margin-top: 1em;
  font-size: 8pt;
}
table#log th {
  text-align: left;
}

.login {
  font-family: "Raleway", sans-serif;
  margin-top: 4em;
  text-align: center;
  color: #cedde8;
}
.login .validation-error {
  color: black;
}
.login__section {
  margin-top: 1.5em;
}
.login__link {
  color: #cedde8;
}
.login__link:focus {
  outline: none;
  text-decoration: underline;
}
.login__input {
  font-family: "Raleway", sans-serif;
  color: white;
  padding: 0.7em 1.6em;
  margin-bottom: 0.5em;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  text-align: center;
  width: 90%;
  max-width: 22em;
}
.login__input::placeholder {
  color: white;
}
.login__input:focus {
  outline: none;
  background-color: hsla(0, 0%, 0%, 0.03);
}
.login__input--invalid {
  color: hsl(0, 80%, 65%);
  border-bottom-color: hsl(0, 80%, 65%);
}
.login__main-button {
  color: #83929e;
  font-size: 14px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.2em;
  padding: 0.7em 0 0.5em;
  width: 90%;
  max-width: 22em;
  letter-spacing: 0.5px;
}
.login__main-button:hover {
  background-color: hsl(0, 0%, 95%);
}
.login__main-button:focus {
  outline: none;
  border-color: hsla(0, 0%, 0%, 0.5);
}
.login__mode-button {
  color: #83929e;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 12px;
  margin: 0 0.3em;
}
.login__mode-button:focus {
  outline: none;
  border-color: hsla(0, 0%, 100%, 0.8);
}
.login__mode-button--selected {
  background-color: hsla(0, 0%, 0%, 0.1);
}
.login__mode-explanation {
  position: relative;
  border: 1px solid #f2f2f2;
  max-width: 40em;
  white-space: pre-wrap;
  margin: 1em auto;
  padding: 1em;
  font-size: 90%;
  background-color: hsla(0, 0%, 0%, 0.1);
}
.login__mode-explanation-close {
  position: absolute;
  top: 0;
  right: 4px;
  color: white;
}

.logo {
  max-width: 600px;
  max-height: 84px;
}

.lowlight {
  white-space: pre;
  font-family: monospace;
  word-wrap: break-word;
}
.lowlight .line-number {
  display: inline-block;
  width: 3em;
  text-align: right;
  padding-right: 0.6em;
  color: hsl(25, 20%, 50%);
}
.lowlight .hljs-attr {
  color: hsl(80, 100%, 30%);
}
.lowlight .hljs-string {
  color: hsl(20, 100%, 40%);
}
.lowlight .hljs-literal,
.lowlight .hljs-number {
  color: hsl(200, 100%, 40%);
}
.lowlight .hljs-keyword {
  color: hsl(200, 60%, 40%);
}
.lowlight .hljs-params,
.lowlight .hljs-built_in {
  color: hsl(290, 60%, 50%);
}
.lowlight .hljs-comment {
  color: hsl(0, 0%, 75%);
}

.menu {
  font-family: "Raleway", sans-serif;
  width: 20rem;
  transition: width 0.5s;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  overflow-y: auto;
  height: 100vh;
  padding: 2rem;
  overflow-x: hidden;
}
.menu__link {
  color: hsl(0, 0%, 95%);
}
.menu__link:hover {
  color: hsl(0, 0%, 80%);
}
.menu__item {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin-top: 1.2em;
  margin-bottom: 0;
}
.menu__item-text {
  transition: opacity 0.5s;
}
.menu__icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.8em;
}
.menu__section {
  margin-left: 3em;
  font-size: 0.85714rem;
  font-weight: 200;
  line-height: 1.7;
  letter-spacing: 0.12em;
}
.menu.closing, .menu.closed {
  width: 5.6rem;
}
.menu.closing .menu__item-text, .menu.closed .menu__item-text, .menu.opening .menu__item-text {
  position: absolute;
  opacity: 0;
  white-space: nowrap;
}

.menu-toggle {
  cursor: pointer;
  width: 18px;
  transition: margin-left 0.5s;
  fill: hsl(0, 0%, 95%);
  margin-bottom: 1.5em;
}
.menu-toggle .top-bar,
.menu-toggle .middle-bar,
.menu-toggle .bottom-bar {
  transform-origin: center;
  transition: all 0.5s;
}
.open .menu-toggle, .opening .menu-toggle {
  margin-left: calc(100% - 18px);
}
.open .menu-toggle .top-bar,
.open .menu-toggle .middle-bar {
  transform: rotate(45deg);
}
.open .menu-toggle .bottom-bar {
  transform: rotate(-45deg);
}
.closed .menu-toggle .top-bar {
  transform: translateY(-30px);
}
.closed .menu-toggle .bottom-bar {
  transform: translateY(30px);
}

div.month-input button {
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-size: 0.85714rem;
  min-width: 5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 0.4em;
}

div.month-picker {
  position: absolute;
  z-index: 2;
  border: 1px solid hsl(0, 0%, 87%);
  background-color: white;
  padding: 1em;
  white-space: nowrap;
}

select.year-dropdown {
  font-size: 0.85714rem;
  width: 13.5rem;
  text-align-last: center;
}
select.year-dropdown option {
  background-color: white;
  color: black;
}

.no-data {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.no-data__text {
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 3.6vw;
  line-height: 1.5em;
  letter-spacing: 0.2em;
}
.no-data__button {
  margin-top: 3em;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  color: hsla(0, 0%, 100%, 0.7);
}
.no-data__button:hover:enabled {
  background-color: hsla(0, 0%, 0%, 0.1);
}

#errors,
#warnings {
  font-family: "Roboto", sans-serif;
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  padding-top: 25em;
  z-index: 4;
  pointer-events: none;
}
#errors .error-panel,
#errors .warning-panel,
#warnings .error-panel,
#warnings .warning-panel {
  pointer-events: auto;
  color: white;
  background-color: hsl(230, 30%, 57%);
  box-shadow: 0 0 4em hsla(0, 0%, 70%, 0.7);
}

.error-table-header {
  background-color: hsla(0, 0%, 0%, 0.1);
}

.DayPicker {
  position: absolute;
  display: block;
  background-color: white;
  color: black;
  border: 1px solid hsl(0, 0%, 87%);
  z-index: 2;
}

.DayPicker-Day {
  font-family: "Roboto", sans-serif;
}

.DayPicker-Month {
  margin: 1rem 0.5rem 0;
}

.DayPicker-NavButton {
  top: 1.3em;
  right: 0.7em;
}
.DayPicker-NavButton--prev {
  margin-right: 1.3em;
}

.DayPicker-wrapper {
  padding-bottom: 0.5rem;
}

.password-reset {
  font-family: "Raleway", sans-serif;
  margin-top: 4em;
  text-align: center;
  color: #cedde8;
}
.password-reset__message {
  font-size: 150%;
  font-weight: 200;
  line-height: 1.9;
  white-space: pre-wrap;
  color: white;
  margin-bottom: 2em;
}
.password-reset__section {
  margin-top: 1.5em;
}
.password-reset__input {
  font-family: "Raleway", sans-serif;
  color: white;
  padding: 0.7em 1.6em;
  margin-bottom: 0.5em;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  text-align: center;
  width: 90%;
  max-width: 22em;
}
.password-reset__input::placeholder {
  color: white;
}
.password-reset__input:focus {
  outline: none;
  background-color: hsla(0, 0%, 0%, 0.03);
}
.password-reset__input--invalid {
  color: hsl(0, 80%, 65%);
  border-bottom-color: hsl(0, 80%, 65%);
}
.password-reset__main-button {
  color: #83929e;
  font-size: 14px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.2em;
  padding: 0.7em 0 0.5em;
  width: 90%;
  max-width: 22em;
  letter-spacing: 0.5px;
}
.password-reset__main-button:hover {
  background-color: hsl(0, 0%, 95%);
}
.password-reset__main-button:focus {
  outline: none;
  border-color: hsla(0, 0%, 0%, 0.5);
}

.payment__previous-list {
  padding-left: 1.5em;
  margin: 0.5em 0;
}
.payment__previous-item {
  margin-top: 0.5em;
}
.payment__full-buttons {
  display: flex;
}
.payment__full-button {
  flex-grow: 1;
}
.payment__money-form-buttons {
  width: 100%;
}
.payment__save-button {
  margin-top: 2em;
  width: 100%;
}

svg.arc-graph.radial-progress {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}
svg.arc-graph.radial-progress circle.bg {
  stroke: hsl(0, 0%, 70%);
}
svg.arc-graph.radial-progress circle.fg {
  stroke: #ff4350;
}

.report-time-overlay {
  position: absolute;
  z-index: 2;
  right: 0;
  border: 1px solid hsl(0, 0%, 87%);
  background-color: white;
  padding: 1em;
  margin-top: 0.5em;
  text-align: right;
}
.report-time-overlay__time-button {
  width: 12em;
}

.day-of-month {
  text-align: right;
  width: 3.5em;
}

.balance-date-mode-button,
.report-period-mode-button {
  width: 18em;
}

.income-statement,
.cash-flow-statement {
  min-width: 60em;
}

.income-statement__account-name {
  padding-left: 0;
}
.income-statement__account-name--total {
  font-weight: bold;
}
.income-statement__account-name--level2 {
  padding-left: 1.5em;
}
.income-statement__account-name--level3 {
  padding-left: 3em;
}
.income-statement__account-name--level4 {
  padding-left: 4.5em;
}
.income-statement__total-row {
  border-top: 1px solid hsl(210, 60%, 93%);
  text-transform: uppercase;
}

.chart-of-accounts__account-name {
  padding-left: 0;
}
.chart-of-accounts__account-name--level1 {
  font-weight: bold;
}
.chart-of-accounts__account-name--level2 {
  padding-left: 1.5em;
}
.chart-of-accounts__account-name--level3 {
  padding-left: 3em;
}
.chart-of-accounts__account-name--level4 {
  padding-left: 4.5em;
}

.cash-flow-title {
  margin-bottom: 0;
  padding-bottom: 0;
}

.cash-flow-method {
  margin-bottom: 3em;
  text-transform: uppercase;
}

.revenue-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.revenue-preview__buttons {
  padding-top: 4em;
  padding-bottom: 2em;
}
.revenue-preview__button {
  width: 12em;
}
.revenue-preview__flag {
  height: 1.5em;
  padding-left: 0.5em;
  vertical-align: middle;
  cursor: pointer;
}
.revenue-preview__pdf {
  width: 100%;
  max-width: 780px;
  height: 1000px;
  margin-bottom: 4em;
}

div#loading-js {
  padding: 1em;
  color: white;
}

div#severe-error {
  padding: 1em;
  white-space: pre-wrap;
}

div#root {
  background: linear-gradient(135deg, #4e328c 0%, #9dd8e6 70%) fixed;
  min-height: 100vh;
}

div#body {
  display: flex;
}

div#main {
  font-family: "Roboto", sans-serif;
  flex-grow: 1;
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
}

.company-selection {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8em;
  letter-spacing: 0.03em;
}
.company-selection__button {
  font-family: "Roboto", sans-serif;
  font-size: 150%;
  font-weight: 300;
  letter-spacing: 0.2em;
  padding: 0.1em 2.4em;
  background-color: hsla(0, 0%, 0%, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
}
.company-selection__button:hover {
  background-color: hsla(0, 0%, 0%, 0.2);
}

.settings-menu__title {
  padding-bottom: 0;
}
.settings-menu__link {
  color: hsl(0, 0%, 95%);
}
.settings-menu__link:hover {
  color: hsl(0, 0%, 80%);
}
.settings-menu__section {
  margin-left: 3em;
  font-size: 0.85714rem;
  font-weight: 200;
  line-height: 1.7;
  letter-spacing: 0.12em;
  font-family: "Raleway", sans-serif;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  line-height: 1.6;
}
.settings__input-row {
  padding: 0.3em 0;
  display: flex;
}
.settings__input-row--invoice-term {
  align-items: center;
}
.settings__label {
  width: 10em;
}
.settings__logo {
  border: 2px solid hsl(252, 40%, 97%);
  padding: 1.6em;
  box-sizing: content-box;
}
.settings__invoice-term-input {
  width: 3em;
  text-align: right;
}
.settings__close-account-button {
  background-color: #ff4350;
}

.sidebar {
  font-size: 90%;
  width: 20rem;
  flex-shrink: 0;
  padding: 2rem;
  background-color: hsla(0, 0%, 0%, 0.1);
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 300;
}
.sidebar .validation-error {
  color: hsl(303, 88%, 22%);
  font-size: 100%;
  font-weight: 400;
}
.sidebar--flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.sidebar__link {
  color: hsl(0, 0%, 95%);
  font-weight: 500;
}
.sidebar__link:hover {
  color: hsl(0, 0%, 80%);
}
.sidebar__title, .sidebar__title-with-icon {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.2em;
  color: white;
}
.sidebar__title {
  padding-top: 0.2rem;
  padding-bottom: 0.5em;
}
.sidebar__title-with-icon {
  display: flex;
}
.sidebar__title-icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.8em;
  flex-shrink: 0;
}
.sidebar__section-title {
  font-weight: 500;
  letter-spacing: 0.1em;
  color: white;
}
.sidebar__input {
  font-size: 0.85714rem;
}
.sidebar__wide-button {
  width: 100%;
}
.sidebar__strong {
  font-weight: bold;
  color: white;
}
.sidebar__warning {
  color: black;
}

.signup {
  width: 90%;
  max-width: 50em;
  margin: 4em auto;
  text-align: center;
  color: white;
}
.signup .validation-error {
  color: black;
}
.signup__section {
  margin-top: 1.5em;
}
.signup__input-row {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}
.signup__input-row-element {
  width: 100%;
  margin: 0 1em;
}
.signup__link {
  color: white;
  font-weight: 600;
}
.signup__link:focus {
  outline: none;
  text-decoration: underline;
}
.signup__input {
  font-family: "Raleway", sans-serif;
  color: white;
  padding: 0.7em 1.6em;
  margin-bottom: 0.5em;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  text-align: center;
  width: 100%;
}
.signup__input::placeholder {
  color: white;
}
.signup__input:focus {
  outline: none;
  background-color: hsla(0, 0%, 0%, 0.03);
}
.signup__input--invalid {
  color: hsl(0, 80%, 65%);
  border-bottom-color: hsl(0, 80%, 65%);
}
.signup__main-button {
  color: #83929e;
  font-size: 14px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.2em;
  padding: 0.7em 0 0.5em;
  width: 90%;
  max-width: 22em;
  letter-spacing: 0.5px;
}
.signup__main-button:hover {
  background-color: hsl(0, 0%, 95%);
}
.signup__main-button:focus {
  outline: none;
  border-color: hsla(0, 0%, 0%, 0.5);
}
.signup__password-recommendation {
  margin-top: 1em;
  white-space: pre-wrap;
  font-size: 85%;
}

.month-navigation,
.year-navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taxes__heading {
  font-size: 1rem;
  width: 8rem;
  text-transform: uppercase;
  border-bottom: 1px solid hsl(210, 60%, 93%);
  padding-top: 2em;
  padding-bottom: 1em;
}
.taxes__month-wrapper {
  display: flex;
  justify-content: flex-end;
}
.taxes__payment-link, .taxes__overpaid-link {
  text-transform: uppercase;
  font-weight: bold;
  color: red;
}
.taxes__checkbox-cell {
  width: 3em;
}

.vat-declaration-link {
  color: black;
  font-weight: bold;
  margin-right: 0.5em;
}

.terms {
  font-size: 80%;
  height: 25em;
  overflow-y: scroll;
  line-height: 1.5;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 1em;
  text-align: left;
  font-weight: 300;
}
.terms::-webkit-scrollbar {
  width: 0.6em;
}
.terms::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.8);
  border-radius: 0.3em;
}
.terms__paragraph {
  margin: 1em 0;
}
.terms__paragraph--align-center {
  text-align: center;
}
.terms__paragraph--bullet {
  display: list-item;
}
.terms__run--bold {
  font-weight: bold;
}
.terms__run--italic {
  font-style: italic;
}
.terms__horizontal-line {
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-bottom: 0;
  margin: 2em 0;
}

table#totals td {
  padding: 0.7em 1.5em;
}
table#totals tr.bottom {
  text-transform: uppercase;
}

.turnover-report {
  min-width: 80em;
}
.turnover-report__last-row-of-group {
  border-bottom: 1px solid hsl(210, 60%, 93%);
}
.turnover-report__first-header, .turnover-report__second-header {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.turnover-report__account-name--level4 {
  padding-left: 2.5em;
}

table#ui-error-log td.toggle {
  white-space: nowrap;
  padding: 0 0.5em;
}
table#ui-error-log td.toggle button {
  min-width: auto;
  color: black;
  font-size: 30px;
  padding: 0 0.1em 0 0.2em;
  line-height: 0.7;
  background: hsla(0, 0%, 0%, 0.12);
  margin-top: 0.2em;
  outline: none;
  border-radius: 0.15em;
}
table#ui-error-log td.details {
  max-width: 60em;
}
table#ui-error-log td.details .original-stack a.highlighted {
  background-color: hsl(50, 100%, 88%);
}
table#ui-error-log td.details td.lib-frame {
  color: #999;
  text-align: center;
}
table#ui-error-log span.alert {
  font-weight: bold;
  color: red;
  margin-left: 0.3em;
  font-size: 125%;
  vertical-align: super;
}
table#ui-error-log table.transformed-stack tr.highlighted {
  background-color: hsl(50, 100%, 88%);
}
table#ui-error-log .source {
  height: 20em;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding-left: 0.3em;
  position: relative;
}
table#ui-error-log .source .lowlight div.highlighted {
  background-color: hsl(50, 100%, 88%);
}

.user-settings {
  position: relative;
  margin: 4em;
  padding: 1em;
  min-height: 30em;
  text-align: center;
  background-color: hsla(0, 0%, 0%, 0.1);
  color: white;
}
.user-settings .validation-error {
  color: black;
  text-align: center;
  padding-bottom: 1.2em;
}
.user-settings__title {
  font-family: "Raleway", sans-serif;
  font-size: 110%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 1em;
}
.user-settings__menu {
  padding-bottom: 3em;
}
.user-settings__menu-link {
  font-size: 75%;
  padding: 0 1em;
  font-weight: 300;
  letter-spacing: 0.15em;
  color: white;
  opacity: 0.8;
}
.user-settings__menu-link--selected {
  font-weight: 500;
}
.user-settings__body {
  width: 90%;
  max-width: 25em;
  margin: auto;
  text-align: left;
}
.user-settings__body--wide {
  max-width: 30em;
}
.user-settings__input-row {
  display: flex;
  padding-bottom: 1em;
}
.user-settings__input-label {
  width: 8em;
  flex-shrink: 0;
  align-self: center;
  opacity: 0.6;
}
.user-settings__table {
  font-size: 1rem;
}
.user-settings__table-header {
  font-size: 90%;
  background-color: transparent;
}
.user-settings__button--wide {
  width: 100%;
}

.validation-error {
  color: #ff4350;
  font-size: 80%;
  text-transform: none;
}

.warning-panel {
  margin: auto;
  min-width: 25em;
  max-width: 50em;
}
.warning-panel .warning {
  display: flex;
  justify-content: space-between;
  padding: 1.2em 1.8em;
  letter-spacing: 0.05em;
}
.warning-panel .close-button {
  cursor: pointer;
  font-size: 200%;
  line-height: 0.5;
  margin-left: 0.5em;
}